import axios from "axios";
import Cookies from "js-cookie";

export const BASE_URL = "https://api.babamarket.dev/v2/";
export const BASE_URL_PROD = "https://dev.babamarket.co.id/v2/";

const ENVIRONMENT = "dev";

function getBaseUrl(env) {
  switch (env) {
    case "dev":
      return "https://api.babamarket.dev/v2";
    case "prod":
      return "https://dev.babamarket.co.id/v2/";
    default:
      throw new Error("Invalid environment");
  }
}

function getSignature(env) {
  switch (env) {
    case "dev":
      return "7yYOmqPGc68kDReiZgSANhqOCB0f/soqXtDjIZ/BhWc=";
    case "prod":
      return "A/Lsr4xaxlGS8AFxP0UwZOTm8zoVHzHxz8dsatDDedM=";
    default:
      throw new Error("Invalid environment");
  }
}

const BASE_URL_FINAL = getBaseUrl(ENVIRONMENT);
const SIGNATURE = getSignature(ENVIRONMENT);

const axiosInstance = axios.create({
  baseURL: BASE_URL_FINAL,
  timeout: 20000,
  headers: {
    "X-SIGNATURE": SIGNATURE,
  },
});

axiosInstance.interceptors.request.use((config) => {
  const token = Cookies.get("token");
  if (token) {
    config.headers["Authorization"] = "Bearer " + token;
  }
  return config;
});

const API = async (
  url,
  options = {
    method: "GET",
    body: {},
    head: {},
    responseType: "json",
  }
) => {
  const request = {
    url,
    method: options.method,
    headers: {
      ...options.head,
      "X-SIGNATURE": SIGNATURE,
    },
    responseType: options.responseType,
    ...(options.method === "POST" ||
    options.method === "PUT" ||
    options.method === "PATCH" ||
    options.method === "DELETE"
      ? { data: options.body }
      : {}),
  };

  try {
    const res = await axiosInstance(request);
    return res.data;
  } catch (error) {
    console.error("Error during API call:", error);
    throw error;
  }
};

const apiProvider = {
  // Auth
  Login: async (params) => {
    return API("login", {
      method: "POST",
      head: {
        "Content-Type": "multipart/form-data",
      },
      body: params,
    }).catch((err) => {
      return err.response;
    });
  },
  Logout: async (token) => {
    return API("logout", {
      method: "POST",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response;
    });
  },

  // Home
  GetHome: async (token, range) => {
    return API(`panel/home?range=${range}`, {
      method: "GET",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response;
    });
  },
  GetProductHome: async (token, range) => {
    return API(`panel/home/product`, {
      method: "GET",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response;
    });
  },

  // End Home

  // Account

  GetProfile: async (token) => {
    return API(`profile`, {
      method: "GET",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response;
    });
  },
  UpdateProfile: async (params, token) => {
    return API(`profile/update`, {
      method: "POST",
      head: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response;
    });
  },
  UpdatePassword: async (params, token) => {
    return API(`password/update`, {
      method: "POST",
      head: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response;
    });
  },
  // End Account

  // Region
  GetRegion: async (token, params) => {
    return API(`region/${params}`, {
      method: "GET",
      head: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response;
    });
  },

  // User
  GetUser: async (token, query, role, page, per_page) => {
    return API(
      `panel/user?keyword=${query}&role=${role}&page=${page}&per_page=${per_page}`,
      {
        method: "GET",
        head: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    ).catch((err) => {
      return err.response;
    });
  },
  GetTransactionUser: async (token, id) => {
    return API(`panel/user/transaction/${id}`, {
      method: "GET",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response;
    });
  },
  AddUser: async (params, token) => {
    return API("panel/user/add", {
      method: "POST",
      head: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response;
    });
  },
  ExportUser: async (role, token) => {
    return API(`panel/user/export?role=${role}`, {
      method: "GET",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      responseType: "arraybuffer",
    });
  },
  UpdateStatusUser: async (token, id) => {
    return API(`panel/user/status/update/${id}`, {
      method: "POST",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response;
    });
  },
  UpdateUser: async (params, token, id) => {
    return API(`panel/user/update/${id}`, {
      method: "POST",
      head: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response;
    });
  },
  DeleteUser: async (token, id) => {
    return API(`panel/user/delete/${id}`, {
      method: "POST",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response;
    });
  },
  // End User

  // Expedition
  GetPriceExpedition: async (token, id) => {
    return API(`panel/expedition/${id}/price`, {
      method: "GET",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response;
    });
  },
  GetPriceDetailExp: async (token, id, id_price) => {
    return API(`panel/expedition/${id}/price/${id_price}`, {
      method: "GET",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response;
    });
  },
  GetPriceExportExp: async (token, id, id_price) => {
    return await API(`panel/expedition/${id}/price/export/${id_price}`, {
      method: "GET",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      responseType: "arraybuffer",
    }).catch((err) => {
      return err.response;
    });
  },
  UpdatePriceExp: async (params, id, id_price, token) => {
    return API(`panel/expedition/${id}/price/update/${id_price}`, {
      method: "POST",
      head: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response;
    });
  },

  AddPriceExp: async (params, id, token) => {
    return API(`panel/expedition/${id}/price/add`, {
      method: "POST",
      head: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response;
    });
  },
  ImportPriceMaterial: async (params, id, id_price, token) => {
    return API(`panel/expedition/${id}/price/import/material/${id_price}`, {
      method: "POST",
      head: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response;
    });
  },
  ImportPriceNonMaterial: async (params, id, id_price, token) => {
    return API(`panel/expedition/${id}/price/import/non-material/${id_price}`, {
      method: "POST",
      head: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response;
    });
  },
  DeletePriceExp: async (id, id_price, token) => {
    return API(`panel/expedition/${id}/price/delete/${id_price}`, {
      method: "POST",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response;
    });
  },
  // Toko
  GetShop: async (token, page, query, per_page) => {
    return API(
      `panel/shop?&page=${page}&keyword=${query}&per_page=${per_page}`,
      {
        method: "GET",
        head: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    ).catch((err) => {
      return err.response;
    });
  },
  GetExportShop: async (token) => {
    return API(`panel/shop/export`, {
      method: "GET",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      responseType: "arraybuffer",
    }).catch((err) => {
      return err.response;
    });
  },
  UpdateShop: async (params, token, id) => {
    return API(`panel/shop/update/${id}`, {
      method: "POST",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response;
    });
  },
  UpdateStatusShop: async (token, id) => {
    return API(`panel/shop/status/update/${id}`, {
      method: "POST",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response;
    });
  },
  DeleteShop: async (token, id) => {
    return API(`panel/shop/delete/${id}`, {
      method: "POST",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response;
    });
  },
  // End Toko

  // Category
  GetCategory: async (token, query, level, parent_id, page, type, per_page) => {
    return API(
      `panel/category?keyword=${query}&level=${level}&parent_id=${parent_id}&page=${page}&type=${type}&per_page=${per_page}`,
      {
        method: "GET",
        head: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    ).catch((err) => {
      return err.response;
    });
  },
  ExportCategory: async (token) => {
    return API(`panel/category/export`, {
      method: "GET",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      responseType: "arraybuffer",
    }).catch((err) => {
      return err.response;
    });
  },
  ImportCategory: async (params, token) => {
    return API(`panel/category/import`, {
      method: "POST",
      head: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response;
    });
  },
  AddCategory: async (params, token) => {
    return API("panel/category/add", {
      method: "POST",
      head: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response;
    });
  },
  UpdateCategory: async (params, token, id) => {
    return API(`panel/category/update/${id}`, {
      method: "POST",
      head: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response;
    });
  },
  UpdateStatusCategory: async (id, token) => {
    return API(`panel/category/status/update/${id}`, {
      method: "POST",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response;
    });
  },
  DeleteCategory: async (token, id) => {
    return API(`panel/category/delete/${id}`, {
      method: "POST",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response;
    });
  },
  // End Category

  // Product
  GetAllProduct: async (token, query, page, per_page) => {
    return API(
      `panel/product?keyword=${query}&page=${page}&per_page=${per_page}`,
      {
        method: "GET",
        head: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    ).catch((err) => {
      return err.response;
    });
  },
  GetExportProduct: async (token) => {
    return API(`panel/product/export`, {
      method: "GET",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      responseType: "arraybuffer",
    }).catch((err) => {
      return err.response;
    });
  },
  UpdateProduct: async (params, id, token) => {
    return API(`panel/product/update/${id}`, {
      method: "POST",
      head: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response;
    });
  },
  GetProductDetail: async (token, id) => {
    return API(`panel/product/${id}`, {
      method: "GET",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response;
    });
  },
  GetProductVariant: async (token, id) => {
    return API(`panel/product/variant/${id}`, {
      method: "GET",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response;
    });
  },
  UpdateProductVariant: async (params, token, id) => {
    return API(`panel/product/variant/update/${id}`, {
      method: "POST",
      head: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response;
    });
  },
  UpdateProductSubVariant: async (params, token, id) => {
    return API(`panel/product/variant/sub/update/${id}`, {
      method: "POST",
      head: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response;
    });
  },
  UpdateProductDetailVariant: async (params, token, id) => {
    return API(`panel/product/variant/detail/update/${id}`, {
      method: "POST",
      head: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response;
    });
  },
  UpdateStatusProduct: async (token, params) => {
    return API(`panel/product/status/update`, {
      method: "POST",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response;
    });
  },
  DeleteProduct: async (token, params) => {
    return API(`panel/product/delete`, {
      method: "POST",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response;
    });
  },

  // Transaction
  GetTransaction: async (token, page, query, per_page) => {
    return API(
      `panel/transaction?page=${page}&keyword=${query}&per_page=${per_page}`,
      {
        method: "GET",
        head: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    ).catch((err) => {
      return err.response;
    });
  },
  GetComplainTransaction: async (token, page, per_page) => {
    return API(`panel/transaction/complain?page=${page}&per_page=${per_page}`, {
      method: "GET",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response;
    });
  },
  UpdateStatusComplain: async (token, id, params) => {
    return API(`panel/transaction/complain/update/${id}`, {
      method: "POST",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response;
    });
  },
  SendMessageComplain: async (token, id, params) => {
    return API(`panel/transaction/complain/discussion/send/${id}`, {
      method: "POST",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response;
    });
  },
  TrackingTransaction: async (token, id) => {
    return API(`transaction/tracking/${id}`, {
      method: "POST",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response;
    });
  },

  // Chat Complain
  GetChatComplainDetail: async (token, id, page, per_page) => {
    return API(
      `/panel/transaction/complain/discussion/${id}?page=${page}&per_page=${per_page}`,
      {
        method: "GET",
        head: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    ).catch((err) => {
      return err.response;
    });
  },
  SendChatComplain: async (params, token, id) => {
    return API(`/panel/transaction/complain/discussion/send/${id}`, {
      method: "POST",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response;
    });
  },

  // Chat
  GetChat: async (token, role) => {
    return API(`panel/conversation?role=${role}`, {
      method: "GET",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response;
    });
  },
  GetDetailChat: async (token, id, page, per_page) => {
    return API(`panel/conversation/${id}?page=${page}&per_page=${per_page}`, {
      method: "GET",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response;
    });
  },
  SendChat: async (params, token, id) => {
    return API(`panel/conversation/send/${id}`, {
      method: "POST",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response;
    });
  },

  // Withdraw
  GetWithdraw: async (token, query, page, per_page) => {
    return API(
      `panel/withdraw?keyword=${query}&page=${page}&per_page=${per_page}`,
      {
        method: "GET",
        head: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    ).catch((err) => {
      return err.response;
    });
  },
  UpdateStatusWithdraw: async (params, token, id) => {
    return API(`panel/withdraw/status/update/${id}`, {
      method: "POST",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response;
    });
  },

  // Report
  GetReport: async (token, start_date, end_date, page, query, per_page) => {
    return API(
      `panel/report?start_date=${start_date}&end_date=${end_date}&page=${page}&keyword=${query}&per_page=${per_page}`,
      {
        method: "GET",
        head: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    ).catch((err) => {
      return err.response;
    });
  },
  ExportReport: async (token, start_date, end_date) => {
    return API(
      `panel/report/export?start_date=${start_date}&end_date=${end_date}`,
      {
        method: "GET",
        head: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        responseType: "arraybuffer",
      }
    );
  },

  // Notification
  GetNotification: async (token, page, per_page) => {
    return API(`panel/notification?page=${page}&per_page=${per_page}`, {
      method: "GET",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response;
    });
  },
  SendNotification: async (params, token) => {
    return API("panel/notification/broadcast", {
      method: "POST",
      head: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response;
    });
  },
  GetNotificationList: async (token, type, page, per_page) => {
    return API(`notification?type=${type}&page=${page}&per_page=${per_page}`, {
      method: "GET",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response;
    });
  },
  GetNotificationCount: async (token) => {
    return API(`notification/count`, {
      method: "GET",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response;
    });
  },
  ReadNotification: async (token, id) => {
    return API(`notification/read/${id}`, {
      method: "POST",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response;
    });
  },
  ReadAllNotification: async (token) => {
    return API(`notification/read/all`, {
      method: "POST",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response;
    });
  },
  DeleteNotification: async (token, id) => {
    return API(`notification/delete/${id}`, {
      method: "POST",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response;
    });
  },

  // Banner
  GetBanner: async (token, page, query, per_page) => {
    return API(
      `panel/banner?page=${page}&keyword=${query}&per_page=${per_page}`,
      {
        method: "GET",
        head: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    ).catch((err) => {
      return err.response;
    });
  },
  AddBanner: async (params, token) => {
    return API("panel/banner/add", {
      method: "POST",
      head: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response;
    });
  },
  UpdateBanner: async (params, token, id) => {
    return API(`panel/banner/update/${id}`, {
      method: "POST",
      head: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response;
    });
  },
  DeleteBanner: async (token, id) => {
    return API(`panel/banner/delete/${id}`, {
      method: "POST",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response;
    });
  },

  // Fee
  GetFee: async (token) => {
    return API(`panel/fee`, {
      method: "GET",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response;
    });
  },
  UpdateFee: async (params, token, id) => {
    return API(`panel/fee/update/${id}`, {
      method: "POST",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response;
    });
  },

  // Payment
  GetPayment: async (token) => {
    return API(`panel/payment`, {
      method: "GET",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response;
    });
  },
  UpdatePayment: async (params, token, id) => {
    return API(`panel/payment/update/${id}`, {
      method: "POST",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response;
    });
  },

  // Version
  GetVersion: async (token) => {
    return API(`version`, {
      method: "GET",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response;
    });
  },
  UpdateVersion: async (params, token, id) => {
    return API(`panel/version/update/${id}`, {
      method: "POST",
      head: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response;
    });
  },
  // End Version

  // Start Log
  GetLog: async (token, app_type, http_status, page, per_page) => {
    return API(
      `panel/log?app_type=${app_type}&http_status=${http_status}&page=${page}&per_page=${per_page}`,
      {
        method: "GET",
        head: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    ).catch((err) => {
      return err.response;
    });
  },

  // Holiday
  GetHoliday: async (token) => {
    return API(`panel/holiday`, {
      method: "GET",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response;
    });
  },
  AddHoliday: async (params, token) => {
    return API("panel/holiday/add", {
      method: "POST",
      head: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response;
    });
  },
  UpdateHoliday: async (params, token, id) => {
    return API(`panel/holiday/update/${id}`, {
      method: "POST",
      head: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      body: params,
    }).catch((err) => {
      return err.response;
    });
  },
  DeleteHoliday: async (token, id) => {
    return API(`panel/holiday/delete/${id}`, {
      method: "POST",
      head: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).catch((err) => {
      return err.response;
    });
  },
};

export default apiProvider;
